import React from 'react';
import { string } from 'prop-types';
import { Box, Center, Heading, Image } from '@chakra-ui/react';

import QRCard from '@components/Common/QRCard/Lazy';
import WithAnimation from '@components/Common/WithAnimation';

import useGuestInfo from '@hooks/useGuestInfo';
import useShiftTime from '@hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';

import {
  ASSETS_FLOWER_LEFT,
  IMG_AKAD,
  IMG_RECEPTION,
  // IMG_DRESSCODE,
} from '@/constants/assets';
import { ENABLE_MUSLIM_LANGUAGE } from '@/constants/feature-flags';

import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_AKAD_DRESSCODE,
  WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_DRESSCODE,
  GOOGLE_MAPS_AKAD,
  GOOGLE_MAPS_RESEPSI,
  WEDDING_RESEPSI_TIME,
} from '@/constants';

import Detail from './Detail';
import txt from './locales';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ lang, ...rest }) {
  const isInvitation = useInvitation();
  const shiftTime = useShiftTime();
  const { code } = useGuestInfo();

  return (
    <Box padding="42px 24px" bgColor="bgPrimary" {...rest}>
      <Center>
        <Image src={ASSETS_FLOWER_LEFT} height="70px" marginLeft="-170px" marginBottom="-50px" />
      </Center>
      <WithAnimation>
        <Heading
          lineHeight="70%"
          fontWeight="normal"
          size="3xl"
          textAlign="center"
          letterSpacing="2px"
          marginBottom="32px"
          color="mainColorText"
        >
          <span dangerouslySetInnerHTML={{ __html: txt.title[lang] }} />
        </Heading>
      </WithAnimation>
      <Detail
        lang={lang}
        items={[
          {
            // Announcement
            title: ENABLE_MUSLIM_LANGUAGE ? txt.akad[lang] : txt.holy[lang],
            imgUrl: IMG_AKAD,
            date: WEDDING_AKAD_FULLDATE,
            time: WEDDING_AKAD_TIME,
            dresscode: false && WEDDING_AKAD_DRESSCODE,
            location: false && WEDDING_AKAD_LOC_NAME,
            locationRoad: false && WEDDING_AKAD_LOC_ROAD,
            locationUrl: false && GOOGLE_MAPS_AKAD,
            show: !isInvitation,
            // info: `<i><b>*) Family only</b> </i>`,
          },
          
          {
            // Invitation General
            title: ENABLE_MUSLIM_LANGUAGE ? txt.akad[lang] : txt.holy[lang],
            imgUrl: IMG_AKAD,
            date: WEDDING_AKAD_FULLDATE,
            time: WEDDING_AKAD_TIME,
            dresscode: WEDDING_AKAD_DRESSCODE,
            location: WEDDING_AKAD_LOC_NAME,
            locationRoad: WEDDING_AKAD_LOC_ROAD,
            locationUrl: GOOGLE_MAPS_AKAD,
            show: isInvitation && shiftTime ==="Shift",
            // info: `<i><b>*) Family only</b> </i>`,
          },

          {
            // Invitation Shift 1 Pemberkatan
            title: ENABLE_MUSLIM_LANGUAGE ? txt.akad[lang] : txt.holy[lang],
            imgUrl: IMG_AKAD,
            date: WEDDING_AKAD_FULLDATE,
            time: WEDDING_AKAD_TIME,
            dresscode: WEDDING_AKAD_DRESSCODE,
            location: WEDDING_AKAD_LOC_NAME,
            locationRoad: WEDDING_AKAD_LOC_ROAD,
            locationUrl: GOOGLE_MAPS_AKAD,
            show: isInvitation && shiftTime ==="Pemberkatan",
            // info: `<i><b>*) Family only</b> </i>`,
          },
          
          {
            // Invitation Shift 2 Pemberkatan & Resepsi
            title: ENABLE_MUSLIM_LANGUAGE ? txt.akad[lang] : txt.holy[lang],
            imgUrl: IMG_AKAD,
            date: WEDDING_AKAD_FULLDATE,
            time: WEDDING_AKAD_TIME,
            dresscode: WEDDING_AKAD_DRESSCODE,
            location: WEDDING_AKAD_LOC_NAME,
            locationRoad: WEDDING_AKAD_LOC_ROAD,
            locationUrl: GOOGLE_MAPS_AKAD,
            show: isInvitation && shiftTime ==="Pemberkatan-Resepsi",
            // info: `<i><b>*) Family only</b> </i>`,
          },
          {
            // Invitation Shift 2 Pemberkatan & Resepsi
            title: txt.reception[lang],
            imgUrl: IMG_RECEPTION,
            date: WEDDING_RESEPSI_FULLDATE,
            time: WEDDING_RESEPSI_TIME,
            dresscode: WEDDING_RESEPSI_DRESSCODE,
            location: WEDDING_RESEPSI_LOC_NAME,
            locationRoad: WEDDING_RESEPSI_LOC_ROAD,
            locationUrl: GOOGLE_MAPS_RESEPSI,
            info: `*) Sitting dinner event concept.`,
            show: isInvitation && shiftTime ==="Pemberkatan-Resepsi",
            // dresscodeUrl: IMG_DRESSCODE,
          },
        ]}
      />
      {code && <QRCard marginTop="32px" />}
    </Box>
  );
}

WeddingSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(WeddingSection);