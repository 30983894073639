import React from 'react';
import { Box, Center, Text, Link, Heading, Image, Divider } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

import LazyImage from '@components/Common/LazyImage';
import WithAnimation from '@/components/Common/WithAnimation';

import { ENABLE_COLLABORATION, ENABLE_MUSLIM_LANGUAGE, SELECTED_VENDOR_COLLABORATION } from '@/constants/feature-flags';
import { IMG_LOGO_INVERT, LOGO_INVITATO } from '@/constants/assets';
import { BRIDE_HASHTAG, INVITATO_URL, SOUND_BY, SOUND_URL, THE_BRIDE } from '@/constants';

import { FOOTER_LINK_PROPS } from './types';
import txt from './locales';

function FooterSection() {
  const isInvitation = useInvitation();
  const lang = useLang();

  return (
    <Box bgColor="bgPrimary" width="100%">
      <Box textAlign="center" color="mainColorText" width="100%" position="relative">
        <Box padding="0 16px 42px 16px">
          <WithAnimation>
            <Text margin="0 0 20px 0" fontWeight="lg">
              Sincere love,
            </Text>
          </WithAnimation>
          <Center>
            <LazyImage maxWidth="80px" src={IMG_LOGO_INVERT} />
          </Center>
          <WithAnimation>
            <Heading
              margin="16px 0"
              fontWeight="normal"
              color="secondaryColorText"
              textAlign="center"
              size="3xl"
              letterSpacing="2px"
              lineHeight="80%"
              dangerouslySetInnerHTML={{ __html: THE_BRIDE.replace('&', '& <br />') }}
            />
          </WithAnimation>
          <WithAnimation>
            <Text margin="24px 0 24px 0" padding="0 12px 0 12px">
              {isInvitation
                ? txt.closingInvitation[lang]
                : txt.closingAnnouncement[lang]
              }
              {ENABLE_MUSLIM_LANGUAGE &&
                <>
                  <br />
                  Wassalamualaikum Warahmatullahi Wabarakatuh.
                </>
              }
            </Text>
          </WithAnimation>
          <WithAnimation>
            <Text fontSize="xl" margin="24px 0 24px 0">{BRIDE_HASHTAG}</Text>
          </WithAnimation>
        </Box>
      </Box>
      {/* Title and Desc Section */}
      <Box bgColor="bgSecondary" padding="24px" textAlign="center">
        {/* INVITATO SECTION */}
        <Box>
          <Center>
            <Link href={INVITATO_URL} target="_blank">
              <LazyImage src={LOGO_INVITATO} maxWidth="100px" margin="0" />
            </Link>
          </Center>
          <Text textAlign="center" color="white" fontSize="xs" margin="-4px 0 12px 0">
            Created with love by Invitato
          </Text>
          {ENABLE_COLLABORATION && (
            <Box margin="-4px 0 16px">
              <Center>
                <Divider
                  margin="4px 0 12px"
                  maxWidth="60px"
                  opacity="1"
                  height="1px"
                  bgColor="mainColorText"
                  border="none"
                  borderBottom="none"
                />
              </Center>
              <Center>
                <Link href={`https://www.instagram.com/${SELECTED_VENDOR_COLLABORATION.instagram}/`} target="_blank">
                <Image
                        src={SELECTED_VENDOR_COLLABORATION.imgUrl}
                        {...SELECTED_VENDOR_COLLABORATION.imgProps}
                      />
                </Link>
              </Center>
              <Center marginTop="2px">
                <Text color="mainColorText" align="center" fontSize="xs">
                In Collaboration with {SELECTED_VENDOR_COLLABORATION.vendorName}
                </Text>
              </Center>
            </Box>
          )}
          <Text {...FOOTER_LINK_PROPS}>
            {`© ${new Date().getFullYear()} ${THE_BRIDE}. All Right Reserved`}
          </Text>
          <Link {...FOOTER_LINK_PROPS} href={SOUND_URL}>
            <Text>{`Song by ${SOUND_BY}`}</Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(FooterSection);